/* Information Bar Component JS File */
window.toggleMenuBar = function() {
	$('body').toggleClass('information-bar-menu-open');
	$(".information-bar-wrapper").toggleClass('menu-open');
	// if ($('body').hasClass('nav-open')) {
	// 	mmenuApi.close();
	// }
};

$("body").on('click', '.toggle-information-bar', function() {
	toggleMenuBar();
});
