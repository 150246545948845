/* Video Block JS File */
$(document).ready(function() {
	if ($(".video-wrapper").length > 0) {
		$(document).on('click', '.video-wrapper .video-player', function() {
			const type = $(this).attr('data-type');
			const video = $(this).attr('data-video');
			const videoFormPopup = {
				video: $(this).attr('data-video'),
				form: $(this).attr('data-form'),
				title: $(this).attr('data-form-title'),
				content: $(this).attr('data-form-content')
			};
			$.magnificPopup.close();
			$.magnificPopup.open({
				type: 'ajax',
				items: {},
				mainClass: 'video-popup fade-in',
				tLoading: coreData.loader,
				ajax: {
					settings: {
						type: "post",
						url: coreData.ajaxurl,
						data: {
							action: "video_block_popup",
							type: type,
							video: video,
							videoFormPopup: videoFormPopup
						},
					},
					cursor: 'mfp-ajax-cur',
					tError: '<a href="%url%">The content</a> could not be loaded.'
				},
				callbacks: {
					ajaxContentAdded: function() {

					}
				}
			});
		})
	}
});
