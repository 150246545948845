/* Instagram Feed Block JS File */
import '../../../src/js/_hoverdir.js';

$(document).ready(function() {
	const windowWidth = $(window).width();

	if (windowWidth >= 1200) {
		$('.instagram-feed-wrapper .sbi_photo').each(function() {
			$(this).hoverdir({
				speed: 50, // Times in ms
				easing: 'ease-in-out',
				hoverDelay: 0, // Times in ms
				inverse: false,
				hoverElem: '.hover-element'
			});
		});
	}

	// $(".instagram-feed-wrapper .slick-instagram-feed").on('init', function() {
	// 	if (windowWidth >= 1200) {
	// 		$('.instagram-feed-wrapper .instagram-post').each(function() {
	// 			$(this).hoverdir({
	// 				speed: 50, // Times in ms
	// 				easing: 'ease-in-out',
	// 				hoverDelay: 0, // Times in ms
	// 				inverse: false,
	// 				hoverElem: '.hover-element'
	// 			});
	// 		});
	// 	}
	// }).slick({
	// 	slidesToShow: 1,
	// 	slidesToScroll: 1,
	// 	autoplay: false,
	// 	autoplaySpeed: 7500,
	// 	speed: 1500,
	// 	mobileFirst: true,
	// 	pauseOnDotsHover: false,
	// 	draggable: false,
	// 	pauseOnFocus: false,
	// 	pauseOnHover: false,
	// 	arrows: true,
	// 	appendArrows: '.instagram-navigation',
	// 	prevArrow: '<span class="prev-arrow"><i class="fal fa-arrow-circle-left"></i></span>',
	// 	nextArrow: '<span class="next-arrow"><i class="fal fa-arrow-circle-right"></i></span>',
	// 	adaptiveHeight: true,
	// 	dots: false,
	// 	focusOnSelect: false,
	// 	rows: 1,
	// 	responsive: [
	// 		{
	// 			breakpoint: 767,
	// 			settings: {
	// 				slidesToShow: 2,
	// 				slidesToScroll: 1,
	// 				rows: 2
	// 			}
	// 		},
	// 		{
	// 			breakpoint: 991,
	// 			settings: {
	// 				slidesToShow: 3,
	// 				slidesToScroll: 1,
	// 				rows: 2
	// 			}
	// 		},
	// 		{
	// 			breakpoint: 1199,
	// 			settings: {
	// 				slidesToShow: 4,
	// 				slidesToScroll: 1,
	// 				rows: 2
	// 			}
	// 		}
	// 	]
	// });
});
