/* Sidebar Block JS File */

import 'jquery-sticky-kit';
import ResizeSensor from 'css-element-queries/src/ResizeSensor';

$(document).ready(function() {
	// remove any blocks that have alignfull that are within the sidebar wrapper
	$(".content-with-sidebar").each(function() {
		$(".sp-block.alignfull", $(this)).removeClass('alignfull');
		$(".sp-block", $(this)).addClass('block-in-sidebar');
	})
});

const $window = $(window);
$window.on('load resize', function() {

	const stickyContainer = '.sticky-container';
	const sidebarWidgets = '.sidebar-widgets';
	const widgetsWrapper = '.widgets-wrapper';
	const aside = '.site-aside';

	function sidebar() {
		const options = {
			parent: stickyContainer,
			offset_top: 120,
			inner_scrolling: true
		};
		const sticky = $(sidebarWidgets).stick_in_parent(options);

		sticky.on("sticky_kit:bottom", function(e) {
			$(sidebarWidgets).addClass('at-bottom');
		}).on("sticky_kit:unbottom", function(e) {
			$(sidebarWidgets).removeClass('at-bottom');
		});
	}

	new ResizeSensor($(widgetsWrapper), function() {
		$(sidebarWidgets).trigger("sticky_kit:recalc");
	});

	$window.on('sidebar:recalc', function(e) {
		setTimeout(function() {
			sidebar();
			console.log('sidebar-resized');
		}, 150);
	});
	sidebar();
});
