/* Banner Video Hub Component JS File */

import ScrollMagic from 'ScrollMagic';
import 'animation.gsap';
import 'debug.addIndicators';
import TimelineMax from 'TimelineMax';

$(document).ready(function() {
	if ($(".banner-video-hub-wrapper").length) {

		if ($(".banner-video-hub-wrapper .banner-background .background").length) {
			const bannerVideoHubController = new ScrollMagic.Controller();
			const bannerVideoHubScene = new ScrollMagic.Scene({
				triggerHook: "onEnter",
				duration: "200%"
			})
			.setTween(".banner-video-hub-wrapper .banner-background .background", {
				y: "40%"
			});
			bannerVideoHubScene.addTo(bannerVideoHubController);
		}
	}
});

