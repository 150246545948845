/* Video Slider Component JS File */
$(document).ready(function() {
	if ($(".video-slider-component").length > 0) {
		let videoSliderSlick = $(".video-slider-wrapper .video-slider-slick");
		if (videoSliderSlick.length > 0) {
			videoSliderSlick.on('init', function() {
				videoSliderSlick.fadeTo('fast', 1);
				lazyLoadInstance.update();
			}).slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				autoplay: true,
				autoplaySpeed: 3000,
				speed: 2000,
				pauseOnDotsHover: false,
				pauseOnFocus: false,
				pauseOnHover: true,
				arrows: false,
				dots: false,
				rows: 0,
				centerMode: true,
				focusOnSelect: false,
				mobileFirst: true,
				responsive: [
					{
						breakpoint: 767,
						settings: {
							slidesToShow: 3,
							slidesToScroll: 1
						}
					},
					{
						breakpoint: 1199,
						settings: {
							slidesToShow: 6,
							slidesToScroll: 1,
							centerMode: false,
							focusOnSelect: false
						}
					}
				]
			}).on('beforeChange', function(event, slick, currentSlide, nextSlide) {
				if ($(".video-slider-wrapper").hasClass('mobile')) {
					$('.slick-slide', $(this)).removeClass('slick-fix');
					if ($(".video-slider-wrapper").hasClass('phone')) {
						$('.slick-center + .slick-cloned', $(this)).addClass('slick-fix');
					} else {
						$('.slick-center + .slick-active.slick-cloned', $(this)).addClass('slick-fix');
					}
				}
				lazyLoadInstance.update();
			});

			console.log('working here');

			$("body").on('mouseenter', '.video-slider-wrapper .video-slide.slick-active', function(event) {
				const $this = $(this);
				if ($(".video-slider-component").hasClass('desktop')) {
					$(this).addClass('active');
					$('.video-slider-wrapper .slick-list').addClass('active');
				}
			}).on('mouseleave', '.video-slider-wrapper .video-slide.slick-active', function() {
				if ($(".video-slider-component").hasClass('desktop')) {
					$(".video-slider-wrapper .video-slide.slick-active").removeClass('active');
					$('.video-slider-wrapper .slick-list').removeClass('active');
				}
			});

			$("body").on('scrollSnap', function(event, data) {
				if (data.block == "video-slider-slick") {
					videoSliderSlick = $('.video-slider-slick', data.element);
					if (videoSliderSlick.length > 0) {
						if (data.direction == 'enter') {
							videoSliderSlick.slick('slickPlay');
						} else if (data.direction == 'exit') {
							videoSliderSlick.slick('slickPause');
						}
					}
				}
			});
		}
	}
});
