window.videoPlayer;

$(document).ready(function () {
	// add the fixed video wrapper to the dom
	$("<div id='fixed-video-wrapper'></div>").prependTo('body');

	$('body').on('click', '.video-popup-trigger', function(e) {
		videoPopup(e, $(this));
	});
})

window.videoPopup = function videoPopup(e, $this) {
	const page = $this.attr('data-page');
	const video = $this.attr('data-video');
	const videoFormPopup = {
		video: $this.attr('data-video'),
		form: $this.attr('data-form'),
		title: $this.attr('data-form-title'),
		content: $this.attr('data-form-content')
	};
	e.stopPropagation();
	e.preventDefault();
	$.magnificPopup.close();
	$.magnificPopup.open({
		type: 'ajax',
		items: {},
		mainClass: 'video-popup fade-in',
		tLoading: coreData.loader,
		ajax: {
			settings: {
				type: "post",
				url: coreData.ajaxurl,
				data: {
					action: "videoPopup",
					page: page,
					video: video,
					videoFormPopup: videoFormPopup
				},
			},
			cursor: 'mfp-ajax-cur',
			tError: '<a href="%url%">The content</a> could not be loaded.'
		},
		callbacks: {
			ajaxContentAdded: function() {

			}
		}
	});
}

$(document).ready(function() {
	let resetBanner;
	const resetBannerTimeout = 2500;
	if ($('.banner-video-popup-trigger').length) {
		ww = $(window).width();
		let isDesktop = ww >= 1200 ? true : false;
		let playsInline = coreData.playInlineOnDesktop;
		let isVideoPlaying = false;
		let autostart = false;
		let bannerHeight = ww >= 1200 ? $("#page-banner-wrapper").outerHeight() : 'auto';
		const videoContainer = "banner-video-player";
		const videoWrapper = $("#" + videoContainer).parent();
		window.videoPlayer = jwplayer(videoContainer);

		// Load video player
		const bannerTrigger = $(".banner-video-popup-trigger");
		if (playsInline == 1) {
			const video = bannerTrigger.attr('data-video');
			const page = bannerTrigger.attr('data-page');
			if ($('.banner-video-popup-trigger')[0].hasAttribute("data-can-autoplay")) {
				autostart = coreData.autoplayBannerVideos !== 'no' ? true : false;
			}
			$.post(coreData.ajaxurl, {
				'action': 'ajax_get_video_manifest',
				'video': video
			}, function(manifest) {
				videoPlayer.setup({
					file: $.parseJSON(manifest),
					autostart: autostart ? true : false,
					controls: true,
					mute: false,
					repeat: false,
					stretching: 'fill',
					width: '100%',
					height: '100%'
				});
				videoPlayer.on('ready', function() {
					if (autostart) {
						setTimeout(function() {
							$('.banner-video-popup-trigger').click();
							videoPlayer.play();
						}, 2000);
					}
				})
			});
		}

		$('.banner-video-popup-trigger').on('click', function(e) {
			ww = $(window).width();
			if (!isDesktop) {
				playsInline = false;
			}
			if (typeof window.forcePopup !== "undefined" && true == window.forcePopup) {
				playsInline = false;
			}
			const video = $(this).attr('data-video');
			const manifest = $(this).attr('data-manifest');
			const videoFormPopup = {
				video: $(this).attr('data-video'),
				form: $(this).attr('data-form'),
				title: $(this).attr('data-form-title'),
				content: $(this).attr('data-form-content')
			};

			if (playsInline == 1) {
				if (videoWrapper.length) {
					$("#banner-video-loading-overlay").addClass('active');
					if (typeof videoPlayer.getState() !== "undefined") {
						videoPlayer.play();
					} else {
						$.post(coreData.ajaxurl, {
							'action': 'ajax_get_video_manifest',
							'video': video
						}, function(manifest) {
							videoPlayer.setup({
								file: $.parseJSON(manifest),
								autostart: false,
								controls: true,
								mute: false,
								repeat: false,
								stretching: 'fill',
								width: '100%',
								height: '100%'
							});
							videoPlayer.play();
						});
					}
					const videoContainerHeight = $("#banner-video-player").outerHeight();
					videoPlayer.on('play', function() {
						playFunctions(videoPlayer);
						clearTimeout(resetBanner);
						$("#banner-video-loading-overlay").removeClass('active');
						$("body").removeClass('banner-video-paused').addClass('banner-video-playing');
						if ($("#page-banner-wrapper").height() !== videoContainerHeight) {
							$("#page-banner-wrapper").animate({
								height: videoContainerHeight
							}, 1000);
						}
					}).on('pause', function() {
						pauseFunctions(videoPlayer);
						resetBanner = setTimeout(function() {
							$("body").removeClass('banner-video-playing').addClass('banner-video-paused');
							if ($("#page-banner-wrapper").height() !== bannerHeight) {
								$("#page-banner-wrapper").animate({
									height: bannerHeight
								}, 1000);
							}
						}, resetBannerTimeout);
					}).on('complete', function() {
						$("body").removeClass('banner-video-paused').removeClass('banner-video-playing');
						completeFunctions(videoPlayer, videoFormPopup);
						if ($("#page-banner-wrapper").height() !== bannerHeight) {
							$("#page-banner-wrapper").animate({
								height: bannerHeight
							}, 1000);
						}
					});
				}
			} else {
				videoPopup(e, $(this));
			}
		});

		$(window).on('resize rotate', function() {
			ww = $(window).width();
			isDesktop = ww >= 1200 ? true : false;
			bannerHeight = ww >= 1200 ? $("#page-banner-wrapper").height() : 'auto';
			isVideoPlaying = $("body").hasClass('banner-video-playing') ? true : false;
			if (isVideoPlaying) {
				$("#page-banner-wrapper").height(videoWrapper.height());
			} else {
				$("#page-banner-wrapper").height(bannerHeight);
			}
		});
	}
});

$(document).ready(function() {
	let isFixed = false;
	window.resetFixed;
	let resetFixedTimeout = 2500;
	let videoFixedContainer;
	let videoFixedContainerID;
	let videoFixedContainerHeight;
	let videoFixedWrapper;
	let videoFixedWrapperHeight;

	window.fixVideo = function fixVideo(callback) {
		isFixed = true;
		videoFixedWrapper.addClass('video-stuck').height(videoFixedContainerHeight);
		$("body").addClass('video-in-window');
		$(videoFixedContainer).appendTo('#fixed-video-wrapper');
		$("#fixed-video-wrapper").addClass('is-playing');
		if (typeof callback !== "undefined") {
			callback();
		}
	}

	window.releaseVideo = function releaseVideo(callback) {
		isFixed = false;
		videoFixedWrapper.removeClass('video-stuck');
		$("body").removeClass('video-in-window');
		$("#fixed-video-wrapper").removeClass('is-playing');
		setTimeout(function() {
			$(videoFixedContainer).appendTo(videoFixedWrapper);
		}, 500);
		if (typeof callback !== "undefined") {
			setTimeout(function() {
				callback();
			}, 600);
		}
	}

	window.pausePlayingVideos = function pausePlayingVideos(playing) {
		const playingContainer = playing.getContainer();
		const playingID = $(playingContainer).attr('id');
		const currentPlaying = $('.jw-state-playing');
		let currentPlayingIDs = [];
		currentPlaying.each(function(i, player) {
			currentPlayingIDs.push(player.id);
		});
		$.each(currentPlayingIDs, function(x, currentPlayingID) {
			if (playingID === currentPlayingID) return true;
			jwplayer(currentPlayingID).pause();
		});
	}

	window.videoStickToWindow = function videoStickToWindow(playing) {

		let ww = $(window).width();
		const activeOnWindowWidth = 1200;
		const activePastScreen = 250;
		videoFixedContainer = playing.getContainer();
		videoFixedContainerID = $(videoFixedContainer).attr('id');
		videoFixedContainerHeight = $("#" + videoFixedContainerID).outerHeight();
		videoFixedWrapper = $(videoFixedContainer).parent();
		videoFixedWrapperHeight = videoFixedWrapper.outerHeight();

		$(window).on('load rotate resize scroll', function() {
			const videoState = playing.getState();
			const videoWrapperTop = $(videoFixedWrapper).offset().top - activePastScreen;
			const videoWrapperBottom = $(videoFixedWrapper).offset().top + $(videoFixedWrapper).outerHeight() + activePastScreen;
			const windowWidth = $(window).width();
			const screenTop = $(window).scrollTop();
			const screenBottom = $(window).scrollTop() + $(window).innerHeight();
			if (windowWidth >= activeOnWindowWidth) {
				if (videoState == 'playing') {
					clearTimeout(window.resetFixed);
					if ((screenBottom > videoWrapperTop) && (screenTop < videoWrapperBottom)) {
						if (isFixed) { releaseVideo(); }
					} else {
						if (!isFixed) { fixVideo(); }
					}
				}
			}
		});
	}

	window.playFunctions = function playFunctions(playing) {
		clearTimeout(window.resetFixed);
		pausePlayingVideos(playing);
		const newVideoContainer = playing.getContainer();
		const newVideoContainerID = $(newVideoContainer).attr('id');
		$("body").removeClass('video-paused').removeClass('video-in-window').addClass('video-playing');
		if (typeof coreData.detachVideos !== "undefined" && true == coreData.detachVideos) {

			if (newVideoContainerID !== videoFixedContainerID) {
				if (isFixed) {
					releaseVideo(function() {
						videoStickToWindow(playing);
					});
				} else {
					videoStickToWindow(playing);
				}
			}
		}
	};

	window.pauseFunctions = function pauseFunctions(playing) {
		$("body").removeClass('video-playing').removeClass('video-in-window').addClass('video-paused');
		if (isFixed) {
			window.resetFixed = setTimeout(function() {
				releaseVideo();
			}, resetFixedTimeout);
		}
	}

	window.completeFunctions = function completeFunctions(playing, formPopupData) {
		$("body").removeClass('video-playing').removeClass('video-in-window').removeClass('video-paused');
		if (isFixed) { releaseVideo(); }
		if (typeof formPopupData.form !== "undefined") {
			videoCompleteFormPopup(formPopupData);
		}
	}
});
