import ResizeSensor from 'css-element-queries/src/ResizeSensor';

$(document).ready(function() {

	const $window = $(window);
	const banner = $("#banner-wrapper");
	const desktopHeader = $('.desktop-header');
	const mobileHeader = $('.mobile-header');
	const desktopTrigger = 200;
	const mobileTrigger = 5;
	let isShrunk = false;
	let isMobileShrunk = false;
	$(window).on('load scroll touchmove resize', function() {

		const scrolled = $(window).scrollTop();

		if ($("body").hasClass('no-banner')) {
			desktopHeader.removeClass('primary-header').addClass('secondary-header')
			mobileHeader.removeClass('primary-header').addClass('secondary-header');
		} else {

			if (scrolled > desktopTrigger) {
				if (isShrunk === false) {
					isShrunk = true;
					desktopHeader.removeClass('primary-header').addClass('secondary-header');
				}
			} else {
				isShrunk = false;
				desktopHeader.removeClass('secondary-header').addClass('primary-header');
			}

			if (scrolled > mobileTrigger) {
				if (isMobileShrunk === false) {
					isMobileShrunk = true;
					mobileHeader.removeClass('primary-header').addClass('secondary-header');
				}
			} else {
				isMobileShrunk = false;
				mobileHeader.removeClass('secondary-header').addClass('primary-header');
			}
		}
	});
});
