import mmenu from 'mmenu-js';

const socialMedia = [];
if (Object.keys(coreData.socialMedia).length) {
	$.each(coreData.socialMedia, function(key, value) {
		socialMedia.push(value.html);
	});
}

$(document).ready(function() {
	const mobileMenu = $('#mobile-menu').mmenu({
		slidingSubmenus: true,
		extensions: [
			"listview-50",
			"border-full",
			"shadow-page",
			"position-right",
			"pagedim-black"
		],
		navbar: {
			title: "Menu"
		},
		onClick: { close: true },
		navbars: [
			{
				height: 1,
				content: ['<a href="' + coreData.home + '"><img alt="Site Logo" src="' + coreData.logoPrimary + '"></a>']
			},
			{
				content: ["prev", "title"]
			},
			// {
			// 	position: 'bottom',
			// 	content: socialMedia
			// }
		],
		hooks: {
			"open:before": function() {
				$("body").addClass('nav-open');
				$("body").removeClass('nav-closed');
				$(".site-nav-hamburger").addClass('is-active');
			},
			"close:before": function() {
				$("body").removeClass('nav-open');
				$("body").addClass('nav-closed');
				$(".site-nav-hamburger").removeClass('is-active');
			}
		}
	}, {
		offCanvas: {
			page: {
				selector: '#page-wrapper'
			}
		}
	});

	// Get the API
	const mmenuApi = mobileMenu.data("mmenu");
	$("body").on('click', '.menu-trigger', function() {
		// const isDesktop = $('html').hasClass('desktop');
		// if (!isDesktop) {
			if ($('body').hasClass('nav-open')) {
				mmenuApi.close();
			} else {
				mmenuApi.open();
			}
		// }
	});

	$(window).on('load rotate resize', function() {
		if ($(window).width() >= 1200) {
			if ($('body').hasClass('nav-open')) {
				mmenuApi.close();
			}
		}
	});
});
