/* Newsletter Grid Block JS File */
$(document).ready(function() {
	if ($(".newsletter-grid-block").length) {

		const baseUrl = location.protocol + '//' + location.hostname + "/" + $(".newsletter-grid-wrapper").attr('data-base') + "/";

		$(".filter-value").select2();

		function newsletterGridFormData() {
			let formData = {};
			$.each($('#newsletter-grid-form').serializeArray(), function() {
				formData[this.name] = this.value;
			});
			return formData;
		}

		function newsletterGridCreateUrl(paged) {
			let formData = newsletterGridFormData();
			const category = typeof formData.category !== "undefined" && formData.category !== 'all' ? formData.category + '/' : '';
			const year = typeof formData.year !== "undefined" && formData.year !== 'all' ? formData.year + '/' : '';
			const monthnum = typeof formData.monthnum !== "undefined" && formData.monthnum !== 'all' ? formData.monthnum + '/' : '';
			const page = typeof paged !== "undefined" ? 'page/' + paged : '';
			return baseUrl + category + year + monthnum + page;
		}

		function newsletterGridPushUrl(paged) {
			if (window.history.replaceState) {
				let formData = newsletterGridFormData();
				window.history.pushState({
					formData: formData,
					paged: paged
				}, null, newsletterGridCreateUrl(paged));
			}
		}

		function newsletterGridLoad(paged) {
			newsletterGridPushUrl(paged);
			$("#newsletter-grid-loader").addClass('active');
			$("#newsletter-grid-load").load(coreData.ajaxurl, {
				action: 'load_newsletter_grid',
				formData: newsletterGridFormData(),
				paged: paged
			}, function(response) {
				$("#newsletter-grid-loader").removeClass('active');
				lazyLoadInstance.update();
			});
		}

		$("#newsletter-grid-form .filter-value").on('select2:select', function() {
			newsletterGridLoad();
		});

		$("#newsletter-grid-load").on('click', "#pagination .paginate-trigger", function() {
			const paged = $(this).attr('data-paged');
			$(".newsletter-grid-wrapper").attr('data-page', paged);
			newsletterGridLoad(paged);
			$("body, html").animate({
                scrollTop: $('.section-filter').offset().top - 200
            }, 750);
		});

		$('#newsletter-grid-load').on('click', '.newsletter-popup-trigger', function(e) {
			e.stopPropagation();
			e.preventDefault();
			const $this = $(this);
			let formData = newsletterGridFormData();
			if (window.history.replaceState) {
				window.history.pushState({
					formData: formData,
				}, null, $this.attr('href'));
			}
			$.magnificPopup.close();
			$.magnificPopup.open({
				type: 'ajax',
				items: {},
				mainClass: 'newsletter-popup fade-in',
				tLoading: coreData.loader,
				ajax: {
					settings: {
						type: "post",
						url: coreData.ajaxurl,
						data: {
							action: "newsletter_popup",
							id: $this.attr('data-id')
						},
					},
					cursor: 'mfp-ajax-cur',
					tError: '<a href="%url%">The content</a> could not be loaded.'
				},
				callbacks: {
					ajaxContentAdded: function() {
					}
				}
			});
		})

		const newsletterLoad = $(".newsletter-popup-trigger.activate");
		if (newsletterLoad.length > 0) {
			newsletterLoad.trigger('click');
		}
	}
});
