import ScrollMagic from 'ScrollMagic';
import 'animation.gsap';
import 'debug.addIndicators';
import TimelineMax from 'TimelineMax';

$(document).ready(function() {
	if ($(".map-and-contact-details-wrapper .google-map-wrapper").length) {

		const mapsBackgroundTween = TweenMax.fromTo(".map-and-contact-details-wrapper .google-map-wrapper", 1, { y: '-25%' }, { y: '20%' });
		const mapsBackgroundController = new ScrollMagic.Controller();
		const mapsBackgroundScene = new ScrollMagic.Scene({
			triggerHook: "onEnter",
			duration: "300%"
		})
		.setTween(mapsBackgroundTween);

		mapsBackgroundScene.addTo(mapsBackgroundController);
	}
});

