/* Banner Video Slider Component JS File */
import slick from 'slick-carousel';
import ScrollMagic from 'ScrollMagic';
import 'animation.gsap';
import 'debug.addIndicators';
import TimelineMax from 'TimelineMax';

$(document).ready(function() {
	if ($(".banner-video-slider-wrapper").length) {
		$(".banner-video-slick-slides").on('init', function() {
		}).slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: false,
			speed: 1000,
			mobileFirst: true,
			pauseOnDotsHover: false,
			pauseOnFocus: false,
			pauseOnHover: true,
			focusOnSelect: true,
			centerMode: true,
			centerPadding: '18%',
			arrows: false,
			dots: false,
			fade: false,
			draggable: false,
			asNavFor: '.banner-video-slick-posters',
			cssEase: 'linear',
			responsive: [
				{
					breakpoint: 767,
					settings: {
						focusOnSelect: true,
						centerMode: true,
						centerPadding: '25%',
						fade: false,
						slidesToShow: 1,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 991,
					settings: {
						focusOnSelect: true,
						centerMode: true,
						centerPadding: '30%',
						fade: false,
						slidesToShow: 1,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 1199,
					settings: 'unslick'
				}
			]
		}).on('beforeChange', function(event, slick, currentSlide, nextSlide) {
			$('.slick-slide', $(this)).removeClass('slick-fix');
			$('.slick-active', $(this)).next().addClass('slick-fix');
		}).on('afterChange', function(event, slick, currentSlide) {
		});

		const slickPosters = $('.banner-video-slick-posters').slick({
			speed: 1500,
			slidesToShow: 1,
			slidesToScroll: 1,
			fade: true,
			asNavFor: '.banner-video-slick-slides',
			arrows: false,
			dots: false,
			mobileFirst: true,
			responsive: [
				{
					breakpoint: 1199,
					settings: 'unslick'
				}
			]
		}).on('beforeChange', function(event, slick, currentSlide, nextSlide) {
			$('.slick-slide', $(this)).removeClass('slick-fix');
			$('.slick-active.slick-cloned', $(this)).addClass('slick-fix');
		}).on('afterChange', function(event, slick, currentSlide) {
		});

		function translateTrack(position) {
			return {
				'-sand-transform': 'translate3d(' + -position + 'px, 0, 0)',
				'-ms-transform': 'translate3d(' + -position + 'px, 0, 0)',
				'-o-transform': 'translate3d(' + -position + 'px, 0, 0)',
				'-moz-transform': 'translate3d(' + -position + 'px, 0, 0)',
				'-webkit-transform': 'translate3d(' + -position + 'px, 0, 0)',
				'transform': 'translate3d(' + -position + 'px, 0, 0)'
			}
		}

		let slidesToShow;

		$(window).on('load resize rotate', function() {

			const windowWidth = $(window).width();

			if (windowWidth >= 1200 && windowWidth <= 1920) {
				slidesToShow = 5;
			} else if (windowWidth > 1920) {
				slidesToShow = 7;
			}

			const track = $(".banner-video-slick-slides");
			const posters = $(".banner-video-slick-posters");
			const slide = $(".banner-video-slide", track);
			const slideCount = slide.length;
			const slideWidth = windowWidth / slidesToShow;
			const trackWidth = slideWidth * slideCount;
			const offscreenSlides = slideCount - slidesToShow;
			const overflowWidth = slideWidth * offscreenSlides;
			let activeSlide = 1;

			if (slideCount > slidesToShow) {
				if (slideCount % 2 !== 0) {
					track.css(translateTrack(overflowWidth / 2));
				}
				$('*[data-index=' + activeSlide + ']', track).addClass('active');
				$('*[data-index=' + activeSlide + ']', posters).addClass('active');
			} else {
				$('*[data-index=1]', track).addClass('active');
				$('*[data-index=1]', posters).addClass('active');
			}

			if (windowWidth >= 1200) {
				track.addClass('sp-slider');
				posters.addClass('sp-slider');
				track.width(trackWidth).fadeTo(500, 1);
				slide.width(slideWidth);
				track.on('mousemove', function(e) {
					const offset = ((((event.pageX) * overflowWidth) / windowWidth).toFixed(1));
					if (slideCount > slidesToShow) {
						track.css(translateTrack(offset));
					}
				});

				track.on('mouseover', '.banner-video-slide', function(e) {
					const slickActiveId = $(this).attr('data-index');
					$(".banner-video-slide", track).removeClass('active');
					$(".banner-video-poster", posters).removeClass('active');
					$('*[data-index=' + slickActiveId + ']', track).addClass('active');
					$('*[data-index=' + slickActiveId + ']', posters).addClass('active');
				});
			} else {
				track.removeClass('sp-slider');
				posters.removeClass('sp-slider');
			}
		});

		const bannerBackgroundController = new ScrollMagic.Controller();
		const bannerBackgroundScene = new ScrollMagic.Scene({
			triggerHook: "onEnter",
			duration: "200%"
		})
		.setTween(".banner-video-slick-posters .section-poster", {
			y: "30%"
		});
		bannerBackgroundScene.addTo(bannerBackgroundController);
	}
});
