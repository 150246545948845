/* Accordion CTA Block JS File */
import ScrollMagic from 'ScrollMagic';
import 'animation.gsap';
import 'debug.addIndicators';
import TimelineMax from 'TimelineMax';
import 'jquery-sticky-kit';
import ResizeSensor from 'css-element-queries/src/ResizeSensor';


$(document).ready(function() {
	if ($(".accordion-cta-wrapper").length) {
		const formCTABackgroundTween = TweenMax.fromTo(".accordion-cta-wrapper .section-background .background", 1, { y: '-25%' }, { y: '15%' });
		const formCTABackgroundController = new ScrollMagic.Controller();
		const formCTABackgroundScene = new ScrollMagic.Scene({
			triggerElement: ".accordion-cta-wrapper .section-background .background",
			triggerHook: "onEnter",
			duration: "300%"
		}).setTween(formCTABackgroundTween);
		formCTABackgroundScene.addTo(formCTABackgroundController);
	}

	function cta_aside() {
		const options = {
			parent: $('.accordion-cta-wrapper .cta-wrapper'),
			offset_top: 200,
			inner_scrolling: true
		};
		const sticky = $('.accordion-cta-wrapper .section-cta').stick_in_parent(options);

		sticky.on("sticky_kit:bottom", function(e) {
			$('.section-cta').addClass('at-bottom');
		}).on("sticky_kit:unbottom", function(e) {
			$('.section-cta').removeClass('at-bottom');
		});
	}
	new ResizeSensor($('.accordion-cta-wrapper'), function() {
		$('.section-cta').trigger("sticky_kit:recalc");
	});

	if ($(window).width() >= 768) {
		cta_aside();
	}
});

