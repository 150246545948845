$(document).ready(function() {

	// Form Popup
	const popup = $(".google-map-load-trigger");
	popup.on("click", function() {
		const mapLoad = $(this).next('.google-map-load');
		const id = $(this).data('id');
		$.magnificPopup.open({
			type: 'ajax',
			items: {},
			mainClass: "google-map-popup-wrapper mfp-with-zoom",
			ajax: {
				settings: {
					type: "post",
					url: coreData.ajaxurl,
					data: {
						action: "loadGoogleMap",
						id: id
					},
				},
				cursor: 'mfp-ajax-cur',
				tError: '<a href="%url%">The content</a> could not be loaded.'
			}
		});
	});
});
