/* Related Videos Component JS File */
$(document).ready(function() {
	if ($(".related-videos-component-slick").length) {
		const relatedVideos = $(".related-videos-component-slick").on('init', function() {
			lazyLoadInstance.update();
		}).slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: false,
			autoplaySpeed: 5000,
			speed: 2000,
			centerMode: true,
			centerPadding: "0",
			pauseOnDotsHover: false,
			pauseOnFocus: false,
			pauseOnHover: true,
			focusOnSelect: false,
			arrows: true,
			dots: false,
			fade: true,
			variableWidth: false,
			appendArrows: '.related-arrows',
			prevArrow: '<i class="fal fa-angle-left"></i>',
			nextArrow: '<i class="fal fa-angle-right"></i>',
			mobileFirst: true,
			adaptiveHeight: true,
			responsive: [
				{
					breakpoint: 767,
					settings: {
						autoplay: true,
						autoplaySpeed: 5000,
						speed: 1500,
						centerMode: true,
						slidesToShow: 3,
						fade: false,
						variableWidth: true,
						adaptiveHeight: false
					}
				}
			]
		}).on('beforeChange', function(event, slick, currentSlide, nextSlide) {
			$(".related-arrows").fadeTo(50, 0);
			$('.slick-slide', $(this)).removeClass('slick-fix');
			$('.slick-active.slick-cloned', $(this)).addClass('slick-fix');
			lazyLoadInstance.update();
		}).on('afterChange', function(event, slick, currentSlide) {
			$(".related-arrows").fadeTo(250, 1);
		});
	}
});
