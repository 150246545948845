/* Partners with Logo Block JS File */
import slick from 'slick-carousel';

$(document).ready(function() {
	if ($(".partners-with-logo-wrapper .slick-partners")) {
		$(".partners-with-logo-wrapper .slick-partners").slick({
			slidesToShow: 3,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 2000,
			speed: 2000,
			mobileFirst: true,
			pauseOnDotsHover: false,
			pauseOnFocus: false,
			pauseOnHover: false,
			arrows: true,
			appendArrows: '.slick-partner-arrows',
			prevArrow: '<span class="prev"><i class="fas fa-arrow-circle-left"></i><span>',
			nextArrow: '<span class="next"><i class="fas fa-arrow-circle-right"></i><span>',
			dots: false,
			rows: 0,
			responsive: [
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 3
					}
				},
				{
					breakpoint: 991,
					settings: {
						slidesToShow: 4
					}
				},
				{
					breakpoint: 1199,
					settings: {
						slidesToShow: 5
					}
				},
				{
					breakpoint: 1499,
					settings: {
						slidesToShow: 6
					}
				},
			]
		});
	}
});
