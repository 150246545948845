/* Tabbed CTA Block JS File */
import tab from 'bootstrap';
import ScrollMagic from 'ScrollMagic';
import 'animation.gsap';
import 'debug.addIndicators';

$(document).ready(function() {
	$('.tabbed-cta').tab();
});

$(document).ready(function() {
	if ($('.tabbed-cta-wrapper .background').length) {
		const tabbedCtaBackgroundTween = TweenMax.fromTo(".tabbed-cta-wrapper .background", 1, { y: '-25%' }, { y: '20%' });
		const tabbedCtaBackgroundController = new ScrollMagic.Controller();
		const tabbedCtaBackgroundScene = new ScrollMagic.Scene({
			triggerElement: ".tabbed-cta-wrapper .background",
			triggerHook: "onEnter",
			duration: "300%"
		}).setTween(tabbedCtaBackgroundTween);
		tabbedCtaBackgroundScene.addTo(tabbedCtaBackgroundController);
	}
});
