/* Boxes CTA Block JS File */
$(document).ready(function() {

	if ($(".boxes-cta-wrapper .start-quiz").length > 0) {

		$(document).on('click', '.boxes-cta-wrapper .start-quiz', function() {

			const back = $(this).closest('.section-back');
			const title = $('.section-title', back).html();
			const content = $('.section-content', back).html();
			const form = $(this).attr('data-form');
			const block = $(".boxes-cta-wrapper").attr('data-id');
			const button = $(".section-boxes-wrapper .content-wrapper .section-button").html();

			if ($(window).width() >= 1200) {
				$(".boxes-cta-wrapper .gravity-loader").addClass('active');
				$(".section-quiz-wrapper #quiz-load").load(coreData.ajaxurl, {
					action: 'load_boxes_cta_quiz',
					page: coreData.pageID,
					title: title,
					content: content,
					form: form,
					block: block,
					button: button
				}, function() {
					$('.boxes-cta-wrapper .section-boxes-wrapper').removeClass('active');
					$(".boxes-cta-wrapper .gravity-loader").removeClass('active');
					setTimeout(function() {
						$(".boxes-cta-wrapper .section-quiz-wrapper").addClass('active');
					}, 600);
				});
			} else {
				$.magnificPopup.close();
				$.magnificPopup.open({
					type: 'ajax',
					items: {},
					mainClass: 'quiz-popup fade-in',
					tLoading: coreData.loader,
					alignTop: true,
					closeOnBgClick: false,
					ajax: {
						settings: {
							type: "post",
							url: coreData.ajaxurl,
							data: {
								action: "load_boxes_cta_quiz_popup",
								page: coreData.pageID,
								title: title,
								content: content,
								form: form,
								block: block,
								button: button
							},
						},
						cursor: 'mfp-ajax-cur',
						tError: '<a href="%url%">The content</a> could not be loaded.'
					},
					callbacks: {
						ajaxContentAdded: function() {
							$(document).on('click', '.gform_next_button, .gform_previous_button', function(e) {
								$(".quiz-popup .gravity-loader").addClass('active');
							});
						}
					}
				});
			}
		});

		$(document).on('click', '.boxes-cta-wrapper .end-quiz', function() {
			$(".boxes-cta-wrapper .section-quiz-wrapper").removeClass('active');
			setTimeout(function() {
				$('.boxes-cta-wrapper .section-boxes-wrapper').addClass('active');
			}, 600);
		});

		$(document).on('click', '.gform_next_button, .gform_previous_button', function(e) {
			$(".boxes-cta-wrapper .gravity-loader").addClass('active');
		});
	}


});
