import ScrollMagic from 'ScrollMagic';
import scrollTo from 'ScrollToPlugin';
import TweenMax from 'TweenMax';
const plugins = [ scrollTo ];
/**
 *
 * Show the scroll back to top functionality if the user has scrolled past set constant
 *
 * @author Peter Brennan <peter.brennan@surfpacific.com.au>
 * @package surfpacific
 * @version 1.0.0
 * @since 0.0.1
 *
 */

let lastScrollTop = 0;
$(document).ready(function() {
	const amountScrolled = 300;
	$(window).on('scroll', function() {
		if ($(window).scrollTop() > amountScrolled) {
			const st = $(this).scrollTop();
			if (st > lastScrollTop) {
				$('#back-to-top').removeClass('in');
			} else {
				$('#back-to-top').addClass('in');
			}
			lastScrollTop = st;
		} else {
			$('#back-to-top').removeClass('in');
		}
	});

	$("body").on('click', '#back-to-top', function() {
		const scrollSnapNavigation = $(".scroll-snap-wrapper nav.scroll-snap-navigation");
		$('a.scroll-snap-list-trigger', scrollSnapNavigation).removeClass('active');
		$('a.scroll-snap-list-trigger[data-snap="#scroll-snap-item-0"]', scrollSnapNavigation).addClass('active');
		const distance = $(window).scrollTop();
		const speed = 3000; // px / sec
		const duration = (distance / speed) < 1.5 ? 1.5 : (distance / speed);
		TweenMax.to(window, duration, { scrollTo: { y: 0, autoKill: true }, ease: scrollSnap.tween,
			onStart: function() {
				scrollSnap.snapActive = false;
			},
			onComplete: function() {
				$('body').removeClass('scroll-locked');
				if (!scrollSnap.snapActive) { scrollSnap.snapActive = true }
			}
		});
		return false;
	});
});

/**
 *
 * Fix the footer to the bottom of the page if body
 * height is not greater then the window height
 *
 * @author Peter Brennan <peter.brennan@surfpacific.com.au>
 * @package surfpacific
 * @version 1.0.0
 * @since 0.0.1
 *
 */
const fixFooter = function() {
	if ($(window).height() > $('body').height()) {
		$('#footer-wrapper').addClass('fixed-bottom Fixed');
	} else {
		$('#footer-wrapper').removeClass('fixed-bottom Fixed');
	}
};

$(window).on('load resize', function() {
	fixFooter();
});
