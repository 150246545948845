/* Banner Video Single Component JS File */
import slick from 'slick-carousel';
import ScrollMagic from 'ScrollMagic';
import 'animation.gsap';
import 'debug.addIndicators';
import TimelineMax from 'TimelineMax';

$(document).ready(function() {
	if ($(".banner-video-single-wrapper").length) {

		$(".banner-video-single-slick").slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 2000,
			speed: 2000,
			mobileFirst: true,
			pauseOnDotsHover: false,
			pauseOnFocus: false,
			pauseOnHover: true,
			arrows: false,
			dots: true,
			fade: true
		});

		const bannerBackgroundController = new ScrollMagic.Controller();
		const bannerBackgroundScene = new ScrollMagic.Scene({
			triggerHook: "onEnter",
			duration: "200%"
		})
		.setTween(".banner-video-single-wrapper .banner-background", {
			y: "30%"
		});
		bannerBackgroundScene.addTo(bannerBackgroundController);
	}
});

