$(document).ready(function() {
	if ($(".icon-template").length > 0) {
		$('body').on('click', '.icon-template .icon-wrapper', function() {
			if ($(this).hasClass('active')) {
				$(this).removeClass('active');
			} else {
				$('.icon-template .icon-wrapper.active').click();
				$(this).addClass('active');
			}
		});
	}
});
