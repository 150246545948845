/* Banner Content Component JS File */
import slick from 'slick-carousel';
import ScrollMagic from 'ScrollMagic';
import 'animation.gsap';
import 'debug.addIndicators';
import TimelineMax from 'TimelineMax';

$(document).ready(function() {
	if ($(".banner-blog-wrapper").length) {

		$(".banner-blog-slick").slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 2000,
			speed: 2000,
			mobileFirst: true,
			pauseOnDotsHover: false,
			pauseOnFocus: false,
			pauseOnHover: true,
			arrows: false,
			dots: true,
			fade: true,
			rows: 0
		});
	}
});

