/* Tabbed Content Block JS File */

$(window).on('resize rotate load', function() {

	if ($('.tabbed-content-wrapper').length > 0) {

		if ($(window).width() < 768) {

			$('.tabbed-content-wrapper').removeClass('is-tabs');
			$('.tabbed-content-wrapper').addClass('is-accordion');

			$('.tabbed-content-wrapper .tab-content .tab-pane').each(function() {
				const element = $(this);
				const elementID = element.attr('data-id');
				element.appendTo("#" + elementID);
			});

			let maxHeight = 0;
			let match;
			match = $('.tabbed-content-wrapper .nav-link .tab-pane');
			match.each(function() {
				if ($(this).height() > maxHeight) { maxHeight = $(this).height() + 200; }
			});

			$(".tabbed-content-wrapper .nav-link .tab-pane").css({ maxHeight: 0 });
			$(".tabbed-content-wrapper .nav-link .tab-pane.show.active").css({ maxHeight: maxHeight });

			$(document).on('click', '.tabbed-content-wrapper .nav-item', function(e) {
				if ($(window).width() < 768) {
					e.preventDefault();
					const $this = $(this);
					$(".tab-pane").removeClass('active show').css({ maxHeight: 0 });
					$(".tab-pane", $this).addClass('active show').css({ maxHeight: maxHeight });
					// setTimeout(function() {
					// 	$('html, body').animate({
					//         scrollTop: $(".tab-pane", $this).offset().top - 200
					//     }, 200);
					// }, 1000);
				}
			});

		} else {

			if ($('.tabbed-content-wrapper').hasClass('is-accordion')) {
				$('.tabbed-content-wrapper .nav-item .tab-pane').each(function() {
					$(this).css({ maxHeight: 'none' }).appendTo(".tabbed-content-wrapper #nav-tab-content");
				});
			}

			$('.tabbed-content-wrapper').removeClass('is-accordion');
			$('.tabbed-content-wrapper').addClass('is-tabs');

		}
	}
});
