/* Gallery Carousel Block JS File */
import slick from 'slick-carousel';
import '../../../src/js/_before-After';

$(document).ready(function() {
	if ($(".gallery-carousel-wrapper").length) {

		$(".patient-slick").each(function(index) {
			const i = index + 1;
			const baCount = $("#patient-slick-" + i).attr('data-ba-count-' + i);
			if (baCount > 1) {
				$("#patient-slick-" + i).slick({
					slidesToShow: 1, slidesToScroll: 1, autoplay: false, /* autoplaySpeed: 2000,*/ speed: 1500, mobileFirst: true, pauseOnDotsHover: false, pauseOnFocus: false, pauseOnHover: false, arrows: false, dots: true, appendDots: '#patient-slide-navigation-' + i, fade: true, draggable: false,
				});
			}
		});

		const gallerySlick = $(".gallery-slick").slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 7500,
			speed: 1500,
			mobileFirst: true,
			pauseOnDotsHover: false,
			pauseOnFocus: true,
			pauseOnHover: true,
			arrows: true,
			prevArrow: '<button type="button" class="slick-prev"></button>',
			nextArrow: '<button type="button" class="slick-next"></button>',
			dots: false,
			fade: true,
			adaptiveHeight: true,
			asNavFor: '.full-gallery-slick',
			draggable: false,
			responsive: [
				{
					breakpoint: 767,
					settings: {
						arrows: false,
						draggable: false,
					}
				}
			]
		}).on("breakpoint", function() {
			$(".before-after-wrapper").beforeAfter();
		});

		const fullGallerySlick = $(".full-gallery-slick").slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 7500,
			speed: 1500,
			mobileFirst: true,
			focusOnSelect: true,
			pauseOnDotsHover: false,
			pauseOnFocus: true,
			pauseOnHover: true,
			arrows: false,
			prevArrow: '<button type="button" class="slick-prev"></button>',
			nextArrow: '<button type="button" class="slick-next"></button>',
			dots: false,
			fade: false,
			rows: 0,
			asNavFor: '.gallery-slick',
			draggable: false,
			responsive: [
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1,
						arrows: true,
						draggable: true,
					}
				},
				{
					breakpoint: 1199,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 1,
						arrows: true,
						draggable: true,
					}
				}
			]
		});


		$(".before-after-wrapper").beforeAfter();

		// $(".before-after-wrapper").each(function(index) {
		// 	const i = index + 1;
		// 	$('#before-after-wrapper-' + i).beforeAfter();
		// });
	}
});

$(window).on('load rotate resize orientationchange', function() {
	if ($(".gallery-carousel-wrapper").length) {
		setTimeout(function() {
			$(".patient-slick").each(function(index) {
				const i = index + 1;
				const baCount = $("#patient-slick-" + i).attr('data-ba-count-' + i);
				if (baCount > 1) {
					$("#patient-slick-" + i).slick('unslick');
					$("#patient-slick-" + i).slick({
						slidesToShow: 1, slidesToScroll: 1, autoplay: false, speed: 1500, mobileFirst: true, pauseOnDotsHover: true, pauseOnFocus: false, pauseOnHover: false, arrows: false, dots: true, appendDots: '#patient-slide-navigation-' + i, fade: true, draggable: false,
					});
					$(".patient-slick .before-after-wrapper").beforeAfter();
				}
			});
		}, 300);
	}
});

