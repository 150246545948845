/* Form CTA Block JS File */
import ScrollMagic from 'ScrollMagic';
import 'animation.gsap';
import 'debug.addIndicators';
import TimelineMax from 'TimelineMax';
$(document).ready(function() {
	if ($('.form-cta-wrapper').length) {
		const formCTABackgroundTween = TweenMax.fromTo(".form-cta-wrapper .section-background", 1, { y: '-25%' }, { y: '20%' });
		const formCTABackgroundController = new ScrollMagic.Controller();
		const formCTABackgroundScene = new ScrollMagic.Scene({
			triggerElement: ".form-cta-wrapper .section-background",
			triggerHook: "onEnter",
			duration: "300%"
		}).setTween(formCTABackgroundTween);
		formCTABackgroundScene.addTo(formCTABackgroundController);
	}
});
