/* Feature Carousel Block JS File */
$(document).ready(function() {
	if ($(".feature-carousel-wrapper .slick-feature-carousel")) {
		const max = parseInt($('.slick-feature-navigation').attr('data-count'), 10);
		$(".feature-carousel-wrapper .slick-feature-carousel").on('init', function() {
			// $('.slick-slide.slick-center', $(this)).addClass('slick-fix-center');
			// $('.slick-slide.slick-active', $(this)).addClass('slick-fix-active');
		}).slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 7500,
			speed: 1000,
			mobileFirst: true,
			pauseOnDotsHover: true,
			draggable: false,
			pauseOnFocus: true,
			pauseOnHover: true,
			arrows: true,
			appendArrows: '.slick-feature-navigation',
			prevArrow: '<span class="slick-navigation"><i class="far fa-angle-left"></i></span><div class="pagination"> <span id="current">1</span> / <span id="max">'+ (max + 1) +'</span>',
			nextArrow: '</div><span class="slick-navigation"><i class="far fa-angle-right"></i></span>',
			dots: false,
			focusOnSelect: true,
			rows: 0,
			fade: true,
			centerMode: true,
			centerPadding: 'inherit',
			responsive: [
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						fade: false,
						centerMode: true,
						centerPadding: 'inherit',
					}
				},
				{
					breakpoint: 991,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						fade: false,
						centerMode: true,
						centerPadding: 'inherit',
					}
				},
				{
					breakpoint: 1199,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 1,
						fade: false,
						centerMode: true,
						centerPadding: 'inherit',
					}
				}
			]
		}).on('beforeChange', function(event, slick, currentSlide, nextSlide) {
			// $('.slick-slide', $(this)).removeClass('slick-fix-center');
			// $('.slick-slide.slick-active', $(this)).addClass('slick-fix-active');
			// $('.slick-slide', $(this)).removeClass('slick-fix');
			// $('.slick-active.slick-cloned', $(this)).addClass('slick-fix');
			$('#current').text(nextSlide + 1);
			if ($(window).width() > 1199) {
				if ((currentSlide == 0 || currentSlide == 1) && nextSlide == max) {
					$('.slick-slide[data-slick-index="' + -2 + '"]').addClass('slick-fix-active');
					$('.slick-slide[data-slick-index="' + -1 + '"]').addClass('slick-fix-active slick-fix-center');
					$('.slick-slide[data-slick-index="' + 0 + '"]').addClass('slick-fix-active');
				} else if (currentSlide == 0 && nextSlide == (max - 1)) {
					$('.slick-slide[data-slick-index="' + -3 + '"]').addClass('slick-fix-active');
					$('.slick-slide[data-slick-index="' + -2 + '"]').addClass('slick-fix-active slick-fix-center');
					$('.slick-slide[data-slick-index="' + -1 + '"]').addClass('slick-fix-active');
				} else if ((currentSlide == max || currentSlide == (max - 1)) && nextSlide == 0) {
					$('.slick-slide[data-slick-index="' + max + '"]').addClass('slick-fix-active');
					$('.slick-slide[data-slick-index="' + (max + 1) + '"]').addClass('slick-fix-active slick-fix-center');
					$('.slick-slide[data-slick-index="' + (max + 2) + '"]').addClass('slick-fix-active');
				}
				else if (currentSlide == max && nextSlide == 1) {
					$('.slick-slide[data-slick-index="' + (max + 1) + '"]').addClass('slick-fix-active');
					$('.slick-slide[data-slick-index="' + (max + 2) + '"]').addClass('slick-fix-active slick-fix-center');
					$('.slick-slide[data-slick-index="' + (max + 3) + '"]').addClass('slick-fix-active');
				}
			} else {
				if (currentSlide == 0 && nextSlide == max) {
					$('.slick-slide[data-slick-index="' + -1 + '"]').addClass('slick-fix-active slick-fix-center');
				} else if (currentSlide == max && nextSlide == 0) {
					$('.slick-slide[data-slick-index="' + (max + 1) + '"]').addClass('slick-fix-active slick-fix-center');
				}
			}

		}).on('afterChange', function(event, slick, currentSlide, nextSlide) {
			// $('.slick-slide.slick-center', $(this)).addClass('slick-fix-center');
			// $('.slick-slide:not(.slick-active)', $(this)).removeClass('slick-fix-active');
				$('.slick-slide').removeClass('slick-fix-active');
				$('.slick-slide').removeClass('slick-fix-center');

		});
	}
});
