window.guidePopup = function guidePopup(e, $this) {
	const guide = $this.data('guide');
	e.stopPropagation();
	e.preventDefault();
	$.magnificPopup.close();
	$.magnificPopup.open({
		type: 'ajax',
		items: {},
		mainClass: 'guide-popup fade-in',
		tLoading: coreData.loader,
		ajax: {
			settings: {
				type: "post",
				url: coreData.ajaxurl,
				data: {
					action: "guide_popup",
					guide: guide
				},
			},
			cursor: 'mfp-ajax-cur',
			tError: '<a href="%url%">The content</a> could not be loaded.'
		},
		callbacks: {
			ajaxContentAdded: function() {

			}
		}
	});
};

$('body').on('click', '.guide-popup-trigger', function(e) {
	guidePopup(e, $(this));
});
