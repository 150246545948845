window.sharePopup = function sharePopup(id, page) {
	$.magnificPopup.close();
	$.magnificPopup.open({
		type: 'ajax',
		items: {},
		mainClass: 'share-popup fade-in',
		tLoading: coreData.loader,
		ajax: {
			settings: {
				type: "post",
				url: coreData.ajaxurl,
				data: {
					action: "share_popup",
					id: id,
					page: page
				},
			},
			cursor: 'mfp-ajax-cur',
			tError: '<a href="%url%">The content</a> could not be loaded.'
		},
		callbacks: {
			ajaxContentAdded: function() {
			}
		}
	});
}

window.socialShare = function socialShare(url) {
	$.magnificPopup.close();
	popupCenter(url, "Share Video", 600, 400);
}

window.emailShare = function emailShare(url, image, title, description) {
	$.magnificPopup.close();
	$.magnificPopup.open({
		type: 'ajax',
		items: {},
		mainClass: 'share-to-email-popup fade-in',
		tLoading: coreData.loader,
		ajax: {
			settings: {
				type: "post",
				url: coreData.ajaxurl,
				data: {
					action: "share_popup_email",
					url: url,
					title: title,
					description: description,
					image: image
				},
			},
			cursor: 'mfp-ajax-cur',
			tError: '<a href="%url%">The content</a> could not be loaded.'
		},
		callbacks: {
			ajaxContentAdded: function() {
			}
		}
	});
}

$('body').on('click', '.trigger-share-popup', function(e) {
	e.preventDefault();
	e.stopPropagation();
	const id = $(this).attr('data-id');
	const page = $(this).attr('data-page');
	sharePopup(id, page);
});
