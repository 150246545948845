/* I Need Help Block JS File */
$(document).ready(function() {
	if ($('.i-need-help-wrapper').length > 0) {
		$(document).on('click', '.i-need-help-wrapper .i-need-help-trigger', function(e) {
			e.preventDefault();
			const parent = $(this).attr('data-parent');
			const slug = $(this).attr('data-slug');
			const url = '/' + parent + '/' + slug + '/';
			$(".i-need-help-wrapper .tab-pane").removeClass('active show');
			$("#" + slug).tab('show');
			if (window.history.replaceState) {
				window.history.pushState({}, null, url);
			}
			$('html, body').animate({
				scrollTop: $('.content-with-sidebar-1').offset().top - 50
			}, 500);
		});
	}
});
