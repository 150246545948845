/* Guide Slider Block JS File */
import slick from 'slick-carousel';

$(document).ready(function() {
	const guideSliderSlick = $(".guide-slider-slick").slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		initialSlide: 1,
		focusOnSelect: true,
		autoplay: false,
		autoPlaySpeed: 1000,
		speed: 1000,
		mobileFirst: true,
		adaptiveHeight: true,
		arrows: true,
		dots: false,
		infinite: true,
		fade: true,
		rows: 0,
		appendArrows: '.guide-slider-arrows',
		prevArrow: '<span class="prev-arrow"><i class="fal fa-arrow-circle-left"></i></span>',
		nextArrow: '<span class="next-arrow"><i class="fal fa-arrow-circle-right"></i></span>',
		responsive: [
			{
				breakpoint: 319,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					initialSlide: 1,
				}
			},
			{
				breakpoint: 767,
				settings: {
					fade: false,
					slidesToShow: 1,
					slidesToScroll: 1,
					initialSlide: 1,
					arrows: false,
				}
			},
			{
				breakpoint: 1019,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					initialSlide: 1,
					arrows: false,
					pauseOnDotsHover: false,
					pauseOnFocus: false,
					pauseOnHover: false,
					fade: false,
				}
			},
			{
				breakpoint: 1199,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					arrows: false,
					fade: false,
				}
			}
		]
	}).on('beforeChange', function(event, slick, currentSlide, nextSlide) {
		$('.slick-slide', $(this)).removeClass('slick-fix');
		$('.slick-active.slick-cloned', $(this)).addClass('slick-fix');
	});

	$("body").on('click', ".start-scroll", function() {
		guideSliderSlick.slick('slickNext');
	})
});
