/* Single Video  Component JS File */
$(document).ready(function() {

	if ($(".single-video-wrapper #single-video-player").length > 0) {
		const videoPlayerElement = $(".single-video-wrapper #single-video-player");
		const video = videoPlayerElement.attr('data-video');
		const form = videoPlayerElement.attr('data-form');
		const formTitle = videoPlayerElement.attr('data-form-title');
		const formContent = videoPlayerElement.attr('data-form-content');
		const videoFormPopup = {
			form: form,
			formTitle: formTitle,
			formContent: formContent
		};
		$.post(coreData.ajaxurl, {
			'action': 'ajax_get_video_manifest',
			'video': video
		}, function(manifest) {
			let singleVideo = jwplayer("single-video-player").setup({
				file: $.parseJSON(manifest),
				autostart: true,
				controls: true,
				mute: false,
				repeat: false,
				stretching: 'fill',
				width: '100%',
				height: '100%'
			});
			singleVideo.on('ready', function() {

			}).on('complete', function() {
				completeFunctions(singleVideo, videoFormPopup);
			});
		});
	}
	// let singleVideo = jwplayer("video-popup-player").setup({
 //        file: "<?php echo jwplayer::manifest($video_id); ?>",
 //        mediaid: "<?php echo $video_id; ?>",
 //        autostart: true
 //    });
 //    singleVideo.on('complete', function() {
 //        const formPopupData = <?php echo json_encode($video_form_popup); ?>;
 //        completeFunctions(singleVideo, formPopupData);
 //    });
});
