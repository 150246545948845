/* Blog Boxes Large Block JS File */
import ScrollMagic from 'ScrollMagic';
import 'animation.gsap';
import 'debug.addIndicators';
import TimelineMax from 'TimelineMax';
$(document).ready(function() {

	if ($('.blog-boxes-background').length) {
		const blogBoxesBackgroundTween = TweenMax.fromTo(".blog-boxes-background", 1, { y: '-25%' }, { y: '20%' });
		const blogBoxesBackgroundController = new ScrollMagic.Controller();
		const blogBoxesBackgroundScene = new ScrollMagic.Scene({
			triggerElement: ".blog-boxes-background",
			triggerHook: "onEnter",
			duration: "300%"
		}).setTween(blogBoxesBackgroundTween);
		blogBoxesBackgroundScene.addTo(blogBoxesBackgroundController);
	}

	if ($(".blog-boxes-large-wrapper").length) {
		const blogPosts = $(".blog-boxes-inner");
		blogPosts.on('mouseenter', function() {
			blogPosts.removeClass('active');
			$(this).addClass('active');
			$(".blog-boxes-large-wrapper .section-body .blog-image .image-wrapper[data-id=" + $(this).attr('data-id') + "]").addClass('active');
			$(".blog-boxes-large-wrapper .section-body .blog-image .image-wrapper[data-id!=" + $(this).attr('data-id') + "]").removeClass('active');
		});
	}
});
