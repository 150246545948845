import ScrollMagic from 'ScrollMagic';
import 'animation.gsap';
import 'debug.addIndicators';
import TimelineMax from 'TimelineMax';
import scrollTo from 'ScrollToPlugin';
const plugins = [ scrollTo ]; // for treeshaking

window.scrollSnap = {
	active: false, // enable / disable scroll snap
	controller: null, // stores the scroll snap controller
	controllers: {}, // stores the additional scroll magic controllers
	sceneForward: null, // stores the forward scenes
	sceneReverse: null, // stores the reverse scenes
	resizeTimer: null, // stores the resize timeout timer
	allBlocks: true, // this will snap all blocks on page by default
	lock: 250, // the time the scroll is locked after the animation completes
	speed: 0.8, // the speed of the tween
	offset: 250, // scroll snap trigger offset
	triggerEvents: true, // trigger snapping events
	tween: Power4.easeOut, // scoll snap tween
	snapActive: true, // scroll snapping active
	count: 0, // the total snapping elements on the page
	navigationActive: true, // show the navigation
	activeSnap: null, // stores the current snapped item
	navigation: $('<ul class="scroll-snap-list"/>'), // the markup for the side navigation
	data: {} // stores snapped data
};

if (scrollSnap.active) {
	$(window).on('load', loadScrollSnap);
}

function loadScrollSnap() {

	function createSnapWrappers(callback) {
		const banner = $("#banner-wrapper");
		if (banner.length > 0) {
			banner.wrap('<div data-snap-id="0" id="scroll-snap-item-0" class="scroll-snap-item" data-state="enabled"></div>');
			if (scrollSnap.navigationActive) { scrollSnap.navigation = scrollSnap.navigation.append($('<li class="scroll-snap-list-item"><a href="javascript:void(0)" data-snap="#scroll-snap-item-0" class="scroll-snap-list-trigger active"><span></span></a></li>')); }
		}
		$('.sp-block').each(function(index, block) {
			index = index + 1;
			scrollSnap.count++;
			let state = "data-state=";
			if (scrollSnap.allBlocks) {
				$(block).addClass('snap');
				state = $(block).attr('class').indexOf('no-snap') > -1 ? 'disabled' : 'enabled';
			} else {
				state = $(block).attr('class').indexOf('snap') > -1 ? 'enabled' : 'disabled';
			}
			const blockName = ($(block)[0].hasAttribute('data-block')) ? $(block).attr('data-block') : false;
			const blockID = ($(block)[0].hasAttribute('data-block-id')) ? $(block).attr('data-block-id') : false;
			const blockClass = (blockName.length > 0 && blockID.length > 0) ? blockName + '-block-' + blockID : false;
			$(block).wrap('<div data-snap-id="' + index + '" id="scroll-snap-item-' + index + '" class="scroll-snap-item" data-state="' + state + '" data-snap-block="' + blockName + '" data-snap-block-id="' + blockID + '" data-snap-block-class="' + blockClass + '"></div>');
			if (scrollSnap.navigationActive) { scrollSnap.navigation = scrollSnap.navigation.append($('<li class="scroll-snap-list-item"><a href="javascript:void(0)" data-snap="#scroll-snap-item-' + index + '" class="scroll-snap-list-trigger"><span></span></a></li>')); }
		});
		if (typeof callback !== "undefined") {
			callback();
		}
	}

	function createNavigation() {
		if (scrollSnap.count > 0) {
			$("body").prepend('<div id="scroll-snap-wrapper"><nav class="scroll-snap-navigation"></nav></div>');
			const scrollSnapNavigation = $("#scroll-snap-wrapper nav.scroll-snap-navigation");
			scrollSnapNavigation.append(scrollSnap.navigation);

			$("body").on("click", "a.scroll-snap-list-trigger", function() {
				const newSnap = $(this).attr('data-snap');
				const newSnapElement = $(newSnap);
				const newSnapOffsetTop = newSnapElement.offset().top;
				const currentSnap = scrollSnap.activeSnap;
				let currentSnapOffsetTop;
				if (currentSnap !== null) {
					const currentSnapElement = $(currentSnap);
					currentSnapOffsetTop = currentSnapElement.offset().top;
				} else {
					currentSnapOffsetTop = 0;
				}
				const scrollSnapDistance = currentSnapOffsetTop > newSnapOffsetTop ? currentSnapOffsetTop - newSnapOffsetTop : newSnapOffsetTop - currentSnapOffsetTop;
				const scrollSnapSpeed = 3000; // px / sec
				const scrollSnapDuration = (scrollSnapDistance / scrollSnapSpeed) < 1.5 ? 1.5 : (scrollSnapDistance / scrollSnapSpeed);
				const scrollSnapPosition = currentSnapOffsetTop < newSnapOffsetTop ? 'top' : 'bottom';

				scrollSnap.data.snapItemID = parseInt(newSnapElement.attr("data-snap-id"));
				scrollSnap.data.snapID = "#scroll-snap-item-" + scrollSnap.data.snapItemID;
				scrollSnap.data.snapElement = $(scrollSnap.data.snapID);
				scrollSnap.data.state = scrollSnap.data.snapElement.attr('data-state');
				scrollSnap.data.position = scrollSnapPosition;
				scrollSnapBlockData(scrollSnap.data.snapElement);
				scrollSnap.data.direction = "enter";
				scrollSnap.activeSnap = scrollSnap.data.snapID;
				triggerSnap(scrollSnap.data.snapID, true);
				triggerEvent(true);
			})
		}
	}

	// Initialize the scrollMagic controller
	function scrollSnapController(callback) {
		// Init new controller
		scrollSnap.controller = new ScrollMagic.Controller();
		// Change behaviour of controller to animate scroll instead of jump
		scrollSnap.controller.scrollTo(function(snapTo, data) {
			TweenMax.to(window, scrollSnap.speed, {scrollTo: { y: snapTo, autoKill: false }, ease: scrollSnap.tween,
				onComplete: function() {
					setTimeout(function() {
						$('body').removeClass('scroll-locked');
						if (!scrollSnap.snapActive) { scrollSnap.snapActive = true }
					}, scrollSnap.lock);
				}
			});
		});
		if (typeof callback !== "undefined") {
			callback();
		}
	}

	function scrollSnapBlockData(snapElement) {
		scrollSnap.data.blockItemID = snapElement.attr('data-snap-block-id') ? parseInt(snapElement.attr('data-snap-block-id')) : false;
		scrollSnap.data.block = snapElement.attr('data-snap-block') ? snapElement.attr('data-snap-block') : false;
		scrollSnap.data.blockID = snapElement.attr('data-snap-block-class') ? "#" + snapElement.attr('data-snap-block-class') : false;
		scrollSnap.data.blockElement = snapElement.attr('data-snap-block-class') ? $(snapElement.attr('data-snap-block-class')) : false;
	}

	// The forward and reverse scenes
	function scrollSnapScenes(callback) {
		$('.scroll-snap-item').each(function(index, snap) {
			scrollSnap.sceneForward = new ScrollMagic.Scene({
				offset: scrollSnap.offset,
				triggerElement: snap,
				triggerHook: 1
			})
			.on('start', function(event) {
				scrollSnap.data.snapItemID = parseInt($(snap).attr("data-snap-id"));
				scrollSnap.data.snapID = "#scroll-snap-item-" + scrollSnap.data.snapItemID;
				scrollSnap.data.snapElement = $(scrollSnap.data.snapID);
				scrollSnap.data.state = scrollSnap.data.snapElement.attr('data-state');
				scrollSnap.data.position = "top";
				scrollSnapBlockData(scrollSnap.data.snapElement);
				// on scroll down entering the next snap
				if (event.scrollDirection == 'FORWARD') {
					scrollSnap.data.direction = "enter";
					scrollSnap.activeSnap = scrollSnap.data.snapID;
					triggerSnap(scrollSnap.data.snapID);
					triggerEvent();
				}
				// on scroll up leaving the previous snap
				if (event.scrollDirection == 'REVERSE') {
					scrollSnap.data.direction = "exit";
					triggerEvent();
				}
			})
			.addTo(scrollSnap.controller);

			// Create scenes for panels, when scrolling reverse
			scrollSnap.sceneReverse = new ScrollMagic.Scene({
				offset: -scrollSnap.offset,
				triggerElement: snap,
				triggerHook: 0
			})
			.on('start', function(event) {
				scrollSnap.data.snapItemID = parseInt($(snap).attr("data-snap-id") - 1);
				scrollSnap.data.snapID = "#scroll-snap-item-" + scrollSnap.data.snapItemID;
				scrollSnap.data.snapElement = $(scrollSnap.data.snapID);
				scrollSnap.data.state = scrollSnap.data.snapElement.attr('data-state');
				scrollSnap.data.position = "bottom";
				scrollSnapBlockData(scrollSnap.data.snapElement);
				// on scroll down leaving the previous section
				if (event.scrollDirection == 'FORWARD') {
					scrollSnap.data.direction = "exit";
					triggerEvent();
				}
				// on scroll up entering the next snap
				if (event.scrollDirection == 'REVERSE') {
					scrollSnap.data.direction = "enter";
					scrollSnap.activeSnap = scrollSnap.data.snapID;
					triggerSnap(scrollSnap.data.snapID);
					triggerEvent();
				}
			})
			.addTo(scrollSnap.controller);
		});
		if (typeof callback !== "undefined") {
			callback();
		}
	}

	function initScrollSnap() {
		// Create snap wrappers
		createSnapWrappers(function() {
			// Create navigation
			if (scrollSnap.navigationActive) { createNavigation() }
			// Initiate snap controller
			scrollSnapController(function() {
				// Init the forward and reverse scenes
				scrollSnapScenes(function() {
					$("body").trigger('scrollSnap:ready');
				});
			});
		});
	}

	window.triggerSnap = function triggerSnap(snapTo, skip) {
		$('body').addClass('scroll-locked');
		// if skip is true it will disable regular snap triggers
		if (typeof skip !== "undefined" && true === skip) {
			scrollSnap.snapActive = false;
			scrollSnap.controller.scrollTo(snapTo, scrollSnap.data);
		}
		if (scrollSnap.snapActive) {
			scrollSnap.controller.scrollTo(snapTo, scrollSnap.data);
		}
	}

	function triggerEvent(skip) {
		// if skip is true it will disable regular snap events
		if (typeof skip !== "undefined" && true === skip) {
			scrollSnap.triggerEvents = false;
			$("body").trigger("scrollSnap", scrollSnap.data);
		}
		if (scrollSnap.triggerEvents) {
			$("body").trigger("scrollSnap", scrollSnap.data);
		}
		if (scrollSnap.data.direction == 'enter') {
			// console.log($('a.scroll-snap-list-trigger[data-snap="#' + scrollSnap.data.snapID + '"]', scrollSnap.navigation));
			$('a.scroll-snap-list-trigger', scrollSnap.navigation).removeClass('active');
			$('a.scroll-snap-list-trigger[data-snap="' + scrollSnap.data.snapID + '"]', scrollSnap.navigation).addClass('active');
		}
	}
	initScrollSnap();
}
